import React from "react"
import styled from "styled-components"
import { FlexContainer } from "../../styles/layout.styled"
import { OUR_SERVICES } from "../../constants"
import ServiceCards from "./ServiceCards"
import { useInView } from "react-intersection-observer"
import { P } from "../../styles/shared.styled"

const SectionTwoContainer = styled(FlexContainer)`
  background: ${({ theme }) => theme.white};
  align-items: center;
  justify-content: center;
  padding: 70px 0 115px;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    padding: 70px 20px 115px;
  }
`

const StyledP = styled(P)`
  opacity: ${({ inView }) => (inView ? 1 : 0)};
  transform: ${({ inView }) =>
    inView ? "translateX(0)" : "translateX(-800px)"};
  transition: all 0.9s ease;
`

const ServiceCardsContainer = styled(FlexContainer)`
  justify-content: space-between;
  max-width: 1440px;
  margin: 80px 0 0;
  flex-wrap: wrap;
`

const OurServices = ({ withAbout = true }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: true,
  })

  return (
    <SectionTwoContainer flexDirection="column">
      <FlexContainer flexDirection="column" ref={ref}>
        {withAbout && (
          <StyledP inView={inView} margin="0 0 20px">
            ABOUT US
          </StyledP>
        )}
        <StyledP
          inView={inView}
          fontWeight="bold"
          fontSize="24px"
          margin="20px 0 0"
        >
          OUR SERVICES
        </StyledP>
      </FlexContainer>
      <ServiceCardsContainer>
        {OUR_SERVICES.map(({ id, icon, description, title, location }, i) => (
          <ServiceCards
            key={id}
            title={title}
            icon={icon}
            description={description}
            location={location}
          />
        ))}
      </ServiceCardsContainer>
    </SectionTwoContainer>
  )
}

export default OurServices
