import React, { useState } from "react"
import styled from "styled-components"
import { FlexContainer } from "../../styles/layout.styled"
import { Link, navigate } from "gatsby"
import { useInView } from "react-intersection-observer"

const ServicesCardContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 25%;
  cursor: pointer;
  align-self: stretch;
  justify-content: flex-start;
  padding: 40px 20px 24px;
  border-radius: 8px;
  box-shadow: ${({ isShown }) =>
    isShown ? "0 0 40px rgba(79, 86, 101, 0.12)" : "unset"};
  background: ${({ isShown, theme }) =>
    isShown ? theme.white : "transparent"};
  transform: ${({ inView }) => (inView ? "scale(1)" : "scale(0.5)")};
  opacity: ${({ inView }) => (inView ? "1" : "0")};
  transition: all 0.3s ease-in-out;

  > a {
    font-weight: bold;
    margin-top: 30px;
    font-size: 12px;
    text-decoration: none;
    color: ${({ theme }) => theme.orange};
    visibility: ${({ isShown }) => (isShown ? "visible" : "hidden")};
    opacity: ${({ isShown }) => (isShown ? "1" : "0")};
    transition: all 0.3s ease;
  }
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    width: 47%;
  }

  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`

const Title = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => `${theme.lightBlue}`};
  margin: 25px 0 16px;
  text-align: center;
`

const Description = styled.span`
  font-size: 18px;
  color: ${({ theme }) => `${theme.lightBlue}CC`};
  text-align: center;
`

const ServiceCards = ({ icon, title, description, location }) => {
  const [isShown, setIsShown] = useState(false)
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
    initialInView: true,
  })
  const handleNavigation = location => {
    navigate(location)
  }

  return (
    <ServicesCardContainer
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      isShown={isShown}
      inView={inView}
      onClick={() => handleNavigation(location)}
    >
      {icon}
      <Title>{title}</Title>
      <Description ref={inViewRef}>{description}</Description>
      <Link to={location}>View Projects</Link>
    </ServicesCardContainer>
  )
}

export default ServiceCards
